const INFO = {
	main: {
		title: "Tom's Blog",
		name: "Tom M.",
		email: "tom.magnus.94@gmail.com",
		logo: "../logo.png",
	},

	socials: {
	// 	// twitter: "https://twitter.com/",
		github: "https://github.com/tom-the-magnus",
	// 	linkedin: "https://linkedin.com/",
	// 	instagram: "https://instagram.com/",
	// 	stackoverflow: "https://stackoverflow.com/",
	// 	facebook: "https://facebook.com/",
	 },

	homepage: {
		title: "Communicator, tinkerer, general inquisitor.",
		description:
			"I am a seasoned client-facing professional with growing abilites in Python, SQL, and Javascript. This website presents my work and also gives people an idea of who I am. It's hosted on Github, deployed on Vercel.",
	},

	about: {
		title: "I’m Tom. I live in New York.",
		description:
			"I've worked in sales at Salesforce and technical support at Teachable. These experiences have taught me how to quickly build rapport with customers, understand their business needs, and design solutions to fit these needs. I started learning programming because of my general interest in technology, my desire to have a more technical career, and my desire to design specific solutions to needs in my life by designing websites, writing bash scripts, or building mobile applications.",
	},

	articles: {
		title: "I'm passionate about technology and solving complex business problems.",
		description:
			"This is a collection of thoughts on technology, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Genre Genie",
			description:
				"This is my portfolio project for CS-340: Intro to Databases. It is a database web-app for a fictional movie business. The GUI for the webapp allows users to execute all CRUD operations for most entities in the database.The database is designe dusing MySQL, MariaDB, and Flask. The front-end is designed using Jinja HTML templates and CSS.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/tom-the-magnus/genre_genie",
		},

		{
			title: "My simple button",
			description:
				"Work in progress! This is a very simple button that I am making to wrap my head around React and the MERN stack.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/tom-the-magnus/my-simple-button",
		},

		{
			title: "Copyleaks Submission App",
			description:
				"Another work in progress! This is a very simple MERN app I am putting together for my interview with Copyleaks.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/tom-the-magnus/copyleaks-demo",
		},

		// {
		// 	title: "Project 2",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 3",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 4",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 5",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },
	],
};

export default INFO;
